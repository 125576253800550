import React, { Component } from "react";

class Soru033 extends Component {
  state = {
    loading: true,
    person: null,
  };

  async componentDidMount() {
    const postId = location.pathname.split("/")[2];

    //console.log("postId", postId);
    const url = `https://api.recordatifocus.com/hippurin-sonuc-03/${postId}`;
    const response = await fetch(url);
    const data = await response.json();
    let outputData = data.map((item) => {
      return {
        cevap: parseInt(item.cevap),
      };
    });
    //console.log(outputData);
    const result = Object.values(outputData).reduce(
      (r, { cevap }) => r + cevap,
      0
    );
    //console.log(result);
    const longeur = data.length;
    //console.log(longeur);

    //console.log((result*100)/longeur);

    const sonuc = 100-((result * 100) / longeur);
    console.log("Soru033 yanlis orani:", sonuc);

    if (sonuc >= 19) {
      this.setState({ person: data, loading: false });
      console.log("slayt gosteriliyor 19 ve daha buyuk istenmeyen cevap verdiginde");
    } else {
    }
  }

  render() {
    return (
    <>
      <swiper-slide>
        <img
          className="img-fluid"
          src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_09.jpg"
        />
    	<div className="slaytno">09. slayt</div>
      </swiper-slide>
    </>
    );
  }
}

export default Soru033;
