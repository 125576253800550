import React, { useContext } from "react";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  function logOut() {
    logout();
    navigate("/");
  }
  return (
    <div>
      {" "}
      <nav className="navbar navbar-expand-lg navbar-lght">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            Anasayfa
          </a>
          <div
            className="collapse navbar-collapse d-none"
            id="navbarTogglerDemo03"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
