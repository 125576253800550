import React, { useContext, useEffect, useEffect2, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./home.css?1235";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

// import soru ile compenant soru sirasinin aynı olması onemli yoksa slayt bozuluyor

import Soru01 from "../components/Soru01";
import Soru02 from "../components/Soru02";
import Soru031 from "../components/Soru031";
import Soru04 from "../components/Soru04";
import Soru032 from "../components/Soru032";
import Soru033 from "../components/Soru033";
import Soru05 from "../components/Soru05";

import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();


const Single = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const postId = location.pathname.split("/")[2];
  const records = posts.slice();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://api.recordatifocus.com/sunum-bilgileri/${postId}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [postId]);



  return (
    <>
      <swiper-container className="SlideSwiper" navigation="true" keyboard="true" effect="fade" pagination="true" pagination-type="fraction" speed="0">
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_01.jpg"
          />
        </swiper-slide>
      <Soru01 />
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_03.jpg"
          />
        </swiper-slide>
      <Soru02 />
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_05.jpg"
          />
        </swiper-slide>
      <Soru031 />
      <Soru04 />
      <Soru032 />
      <Soru033 />
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_10.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_11.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_12.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_13.jpg"
          />
        </swiper-slide>
        <Soru05 />
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_15.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_16.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_17.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_18.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_19.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_20.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_21.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_22.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_23.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_24.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_25.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_26.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_27.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_28.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_29.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_30.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_31.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_32.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_33.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_34.jpg"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            className="img-fluid"
            src="https://slide.recordatifocus.com/upload/Hippurin_Sunum_PTM_MART24_Page_35.jpg"
          />
        </swiper-slide>
      </swiper-container>

      {records.map((post) => (
        <div className="container my-4" key={post.id}>
          <div className="sunum-adi">Sunum Adı: {post.title}</div>
  		  <div className="sunum-adi">Sunum Tarihi: {post.tarih}</div>
        </div>
      ))}
    </>
  );
};

export default Single;
